<template>
  <div class="upload-files">
    <h5>{{ heading }}</h5>
    <p
      class="text-danger fw-bold text-capitalize"
      v-if="getErrors.brokerLogoUpload"
    >
      {{ getErrors.brokerLogoUpload }}
    </p>
    <div class="d-flex align-items-end">
      <label
        class="d-flex justify-content-center align-items-center mb-0 position-relative"
      >
        <div class="position-absolute">
          <img
            v-for="(previewImg, i) in previewImgs"
            :key="i"
            class="upload-files__preview img-fluid mx-1"
            :src="previewImg"
            alt="preview"
          />
        </div>
        <p class="fw-medium mb-0">{{ heading }}</p>
        <input ref="fileInput" type="file" class="d-none" @change="onChange" />
      </label>
      <BButton
        :disabled="getLoadings.brokerLogoUpload || !this.files.length"
        @click="uploadFiles"
        variant="primary"
        class="px-3 ml-3"
        >Upload
        <b-spinner
          v-if="getLoadings.brokerLogoUpload"
          small
          label="Spinning"
          variant="white"
        ></b-spinner>
      </BButton>
      <BButton
        :disabled="getLoadings.brokerLogoUpload || !this.previewImgs.length"
        @click="removeFiles"
        variant="outline-primary"
        class="bg-white border-gray px-3 ml-3"
        >Remove</BButton
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    preview: {
      type: String,
    },
  },
  data() {
    return {
      previewImgs: [],
      files: [],
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  methods: {
    ...mapActions("user", ["uploadBrokerLogo"]),
    onChange(e) {
      this.previewImgs = [];

      let files = e.target.files;

      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          let reader = new FileReader();
          reader.onload = (evt) => {
            this.previewImgs.push(evt.target.result);
          };
          reader.readAsDataURL(files[i]);
        }
      }
      this.files = files;
    },
    async uploadFiles() {
      const res = await this.uploadBrokerLogo({
        body: {
          photo: this.files[0],
        },
      });
      this.$emit("fileUpload", res.photo);
      // send Request to upload files
      // Emit response data
    },
    removeFiles() {
      this.previewImgs.splice(0);
      this.$emit('fileRemoved')
    },
  },
  created() {
    if (this.preview) {
      this.$set(this.previewImgs, 0, this.preview);
    }
  },
};
</script>

<style lang="scss" scoped>
.upload-files {
  label {
    width: 17.5rem;
    height: 109px;
    background: #f6f6f6;
    color: #bfbfbf;
  }
  &__preview {
    max-width: 100px;
  }
}
</style>
