<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <slot name="form-header">
        <p class="text-danger fw-bold text-capitalize" v-if="error.length">
          {{ error }}
        </p>
      </slot>
      <BRow>
        <BCol
          :class="[{ 'col-12': stacked }, colClasses]"
          v-for="{
            name,
            label,
            externalLabel = false,
            component,
            options = [],
            colClasses = 'col-6',
            ...attrs
          } in formFields"
          :key="name"
        >
          <h5 v-if="externalLabel">{{ label }}</h5>

          <component
            :resetForm="resetForm"
            v-if="component === 'RadioInput'"
            :is="component"
            :name="name"
            :label="label"
            v-bind="attrs"
            @update-field="updateField"
            :options="options && options.length ? options : null"
            :value="formData[name]"
          >
          </component>
          <component
            v-on="$listeners"
            v-else
            :is="component"
            :name="name"
            :label="label"
            v-bind="attrs"
            v-model="formData[name]"
            :options="options && options.length ? options : null"
          >
            <template v-if="options.length">
              <option
                v-for="({ value, text, ...rest }, i) in options"
                v-bind="rest"
                :key="i"
                :value="value"
              >
                {{ text }}
              </option>
            </template>
          </component>
        </BCol>
      </BRow>
      <slot name="formfooter">
        <BButton variant="primary" type="submit">Submit</BButton>
      </slot>
    </b-form>
  </ValidationObserver>
</template>

<script>
import TextInput from "@/components/ui/form/controls/TextInput.vue";
import Textarea from "@/components/ui/form/controls/Textarea.vue";
import PasswordInput from "@/components/ui/form/controls/PasswordInput.vue";
import SelectInput from "@/components/ui/form/controls/SelectInput.vue";
import FileInput from "@/components/ui/form/controls/FileInput.vue";
import Datepicker from "@/components/ui/form/controls/Datepicker.vue";
import { ValidationObserver } from "vee-validate";
import MultiselectInput from "@/components/ui/form/controls/MultiSelectInput.vue";
import RadioInput from "@/components/ui/form/controls/RadioInput.vue";
import liveValuesMixin from "@/mixins/liveValuesMixin";

export default {
  components: {
    TextInput,
    Textarea,
    SelectInput,
    FileInput,
    ValidationObserver,
    Datepicker,
    MultiselectInput,
    RadioInput,
    PasswordInput,
  },
  props: {
    formFields: {
      type: Array,
      required: true,
    },
    stacked: {
      default: false,
      type: Boolean,
    },
    error: {
      default: "",
      type: String,
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.formFields.forEach((field) => this.$set(this.formData, field.name, field.value));
    this.addLiveValuesWatchers(this.emitLiveValuesOf);
  },
  data() {
    return { formData: {} };
  },
  methods: {
    onSubmit() {
      this.$emit("on-submit", this.formData);
    },
    updateField(data) {
      const { newVal, name } = data;
      this.formData[name] = newVal;
    },
  },
  watch: {
    resetForm(newVal) {
      if (newVal) {
        this.formFields.forEach((field) =>
          this.$set(this.formData, field.name, field.defaultValue)
        );
        this.$emit("reset-clear", this.formData);
      }
    },
  },
  mixins: [liveValuesMixin],
};
</script>

<style lang="scss" scoped></style>
