<template>
  <ValidationProvider :vid="name" :name="name" :rules="rules" v-slot="{ valid, errors }">
    <b-form-group :class="{ 'floating-label': floatingLabel }">
      <label :for="name">{{ placeholder }}</label>
      <b-form-textarea
        placeholder=" "
        :class="customClass"
        :value="value"
        v-on:input="onInput"
        v-bind="$attrs"
        :state="errors[0] ? false : valid && validate ? true : null"
      ></b-form-textarea>
      <b-form-invalid-feedback id="inputLiveFeedback">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import inputMixin from "@/mixins/inputMixin";
export default {
  mixins: [inputMixin],
  props: {
    floatingLabel: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.broker__addlistings__inputData__textarea {
  height: 365px;
}
</style>
