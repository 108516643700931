<template>
  <Profile
    btnText="Save changes"
    :profileHeading="$t('myProfile')"
    :cardHeading="$t('broker.updateProfile.heading')"
    :formFields="getFormFields"
    :logoPreview="logoPreview"
    class="mt-5"
  />
</template>

<script>
import Profile from "@/components/Broker/Profile.vue";
import { mapGetters } from "vuex";
export default {
  components: { Profile },
  computed: {
    ...mapGetters("auth", ["user"]),
    logoPreview() {
      return this.user && this.user.logo ? this.user.logo : null;
    },
    getFormFields() {
      const formFields = [
        // {
        //   component: "TextInput",
        //   name: "email",
        //   type: "email",
        //   placeholder: this.$t('emailPlaceholder'),
        //   rules: "email",
        // },

        {
          floatingLabel: false,
          component: "TextInput",
          name: "officeName",
          type: "text",
          placeholder: this.$t("broker.updateProfile.officeName"),
          label: {
            main: "Office Name",
            secondary: "(Visible to others)",
          },
          colClasses: "col-12 py-0",
        },
        {
          floatingLabel: false,
          colClasses: "col-12 py-0",
          component: "TextInput",
          name: "phone",
          placeholder: this.$t("phoneNumber"),
          rules: "isValidPhoneNumber",
          label: "Phone Number",
        },

        {
          floatingLabel: false,
          colClasses: "col-12 pt-0",
          component: "TextInput",
          name: "password",
          type: "password",
          placeholder: "Set a new password and save changes",
          label: "New password",
          rules: "min:8",
        },
        {
          floatingLabel: false,
          colClasses: "col-12 pt-0",
          component: "Textarea",
          name: "description",
          type: "password",
          placeholder: "Profile description",
          label: "Profile description",
        },

        // {
        //   component: "FileInput",
        //   name: "logo",
        //   type: "file",
        //   rules: "",
        //   text: this.$t('broker.updateProfile.uploadLogo'),
        //   customClass: "broker__profile__fileinputContainer",
        //   preview:true
        // },
      ];

      if (!this.user) return formFields;

      return formFields.map((field) => {
        if (field.name === "logo") return field;
        return { ...field, value: this.user[field.name] || null };
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
