<template>
  <div class="broker__profile">
    <Cardv2 class="broker__profile__card" :heading="profileHeading">
      <UploadFiles
        class="pb-3 upload-logo"
        heading="Company logo"
        @fileUpload="onLogoUpload"
        @fileRemoved="onFileRemoved"
        :preview="logoPreview"
      />
      <Form
        class="pt-4"
        :formFields="formFields"
        @on-submit="onSubmit"
        :error="getErrors.profile"
      >
        <template v-slot:formfooter>
          <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-primary broker__profile__btn">
              {{ btnText }}
              <b-spinner
                v-if="getLoadings.profile"
                small
                label="Spinning"
                variant="white"
              ></b-spinner>
            </button>
          </div>
        </template>
      </Form>
    </Cardv2>
  </div>
</template>

<script>
import Cardv2 from "../general/CardV2.vue";
import Form from "@/components/ui/form/Form.vue";
import UploadFiles from "../general/UploadFiles.vue";
import { mapActions, mapGetters } from "vuex";
import toastMixin from "@/mixins/toastMixin";

export default {
  mixins: [toastMixin],
  components: { Cardv2, Form, UploadFiles },
  props: ["btnText", "profileHeading", "cardHeading", "formFields", "logoPreview"],
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  data() {
    return {
      logo: null,
    };
  },
  methods: {
    ...mapActions("user", ["updateUser"]),
    async onSubmit(formData) {
      const body = { ...formData, logo: this.logo };

      if (!this.logo) {
        body.logo = null;
      }
      await this.updateUser({ body, type: "broker" });
      this.makeToast(this.$t("Profile Updated Successfully"), "Success");
    },
    onLogoUpload(payload) {
      console.log(payload, "EMITTED");
      this.logo = payload.path;
    },
    onFileRemoved() {
      this.logo = null;
    },
  },
  created() {
    if (this.logoPreview) {
      this.logo = this.logoPreview;
    }
  },
};
</script>

<style lang="scss">
.broker {
  &__profile {
    padding-bottom: 200px;
    &__card {
      max-width: 629px;
      @include xs {
        max-width: 90%;
      }
    }
    &__upload-logo {
      border: 1.5px solid $gray2;
    }
  }
}
</style>
