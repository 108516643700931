export default {
    methods: {
        makeToast(
            message,
            title = this.$t("message2"),
            config = {
                autoHideDelay: 5000,
                appendToast: false,
                variant: "primary",
            }
        ) {
            config.title = title;
            this.$bvToast.toast(message, config);
        },
    },
};
