var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_vm._t("form-header",function(){return [(_vm.error.length)?_c('p',{staticClass:"text-danger fw-bold text-capitalize"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]}),_c('BRow',_vm._l((_vm.formFields),function({
          name,
          label,
          externalLabel = false,
          component,
          options = [],
          colClasses = 'col-6',
          ...attrs
        }){return _c('BCol',{key:name,class:[{ 'col-12': _vm.stacked }, colClasses]},[(externalLabel)?_c('h5',[_vm._v(_vm._s(label))]):_vm._e(),(component === 'RadioInput')?_c(component,_vm._b({tag:"component",attrs:{"resetForm":_vm.resetForm,"name":name,"label":label,"options":options && options.length ? options : null,"value":_vm.formData[name]},on:{"update-field":_vm.updateField}},'component',attrs,false)):_c(component,_vm._g(_vm._b({tag:"component",attrs:{"name":name,"label":label,"options":options && options.length ? options : null},model:{value:(_vm.formData[name]),callback:function ($$v) {_vm.$set(_vm.formData, name, $$v)},expression:"formData[name]"}},'component',attrs,false),_vm.$listeners),[(options.length)?_vm._l((options),function({ value, text, ...rest },i){return _c('option',_vm._b({key:i,domProps:{"value":value}},'option',rest,false),[_vm._v(" "+_vm._s(text)+" ")])}):_vm._e()],2)],1)}),1),_vm._t("formfooter",function(){return [_c('BButton',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Submit")])]})],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }